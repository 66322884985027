<template>
  <div class="elevator">
    <div class="gray-bg">
      <div class="hero">
        <div class="container h-100">
          <div class="row align-items-center h-100">
            <div class="col-md-6">
              <h1 class="wow fadeInUp" data-wow-delay="0s">Hire remote workers & freelancers easily and with confidence</h1>      
              <h5 class="wow fadeInUp" data-wow-delay="0.2s">
                We’re here to help you hire remote workers <br> to take care of your business 24/7</h5>
              <router-link tag="button" class="el-button el-button--success is-round wow fadeInUp" data-wow-delay="0.4s" to="/employer/signup">Sign up now</router-link> 
            </div>
          </div>
        </div>
        
      </div>
      <div class="about">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6 col-sm-9 col-12 wow fadeInUp" data-wow-delay="0s">
              <center>
              <h5>CitronWorks is a global marketplace for freelance developers, virtual assistants, customer support staff, lead generation experts, copywriters, social media and blogging professionals, all available for one-off projects or ongoing work.</h5>      
              </center>
            </div>
          </div>
        </div>
      </div>
      <center>
        <img src="images/green-divider.png" width="90" height="auto">
      </center>
    </div>
    <div class="how-it-works">
      <div class="container">
        <div class="row justify-content-center mb-5 wow fadeInUp" data-wow-delay="0.2s">
          <div class="col-md-6 col-sm-9 col-12">
            <center>
              <h2 class="orange-text mb-0">Our services</h2>
             <!--  <h5 class="wow fadeInUp" data-wow-delay="0.4s">If have any questions, feel free to email</h5>
              <span class="h5 wow fadeInUp" id="obfuscated"><canvas style="width: 155px !important; height: 24px !important; cursor: pointer;"></canvas></span>  -->
              <!-- <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h5>   -->
            </center>
          </div>
        </div>
        <el-row :gutter="30" type="flex" class="mb-4">
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card wow fadeInUp" data-wow-delay="0s">
              <center>
                <img src="images/share-icon.png" width="90" height="auto" class="mb-4">
              </center>
              <h4 class="blue-text mb-3">CitronWorks Marketplace</h4>
              <h5>The standard CitronWorks service lets you search and find freelancers on the platform. This works well for people experienced with hiring remote workers.</h5>  
              <div class="text-right">
                <router-link tag="button" class="el-button el-button--success is-round" to="/employer/signup">Signup</router-link>
              </div>  
            </div>
          </el-col>
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card wow fadeInUp" data-wow-delay="0.2s">
              <center>
                <img src="images/call-icon.png" width="90" height="auto" class="mb-4">
              </center>
              <h4 class="blue-text mb-3">CitronWorks Concierge </h4>  
              <h5>With CitronWorks Concierge, you can get the top freelancers and remote workers delivered right to your inbox. Just tell us what you’re looking for, and we’ll do all the work of finding, interviewing and shortlisting the top candidates for you to choose from.</h5> 
              <div class="text-right">
                <router-link tag="button" class="el-button el-button--success is-round" to="/concierge">Read more</router-link>
              </div>   
            </div>
          </el-col>
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card last wow fadeInUp" data-wow-delay="0.4s">
              <center>  
                <img src="images/rocket-icon.png" width="90" height="auto" class="mb-4">
              </center> 
              <h4 class="blue-text mb-3">CitronWorks Team Management</h4>  
              <h5>With CitronWorks Team Management, we find and manage a remote team for you.  Our senior team have each been hiring and managing remote workers globally for 10+ years – You get that experience and management for YOUR team.</h5>
              <div class="text-right">
                <router-link tag="button" class="el-button el-button--success is-round" to="/citronworkers">Read more</router-link>
              </div>  
            </div>
          </el-col>
        </el-row>
        <!-- <div class="why-cw">
          <center>
            <img src="images/green-divider.png" width="90" height="auto" class="divider">
          </center>
          <h2 class="mb-5 orange-text text-center wow fadeInUp" data-wow-delay="0s">Why choose <span style="font-weight: 600;">CitronWorks?</span></h2>
          <el-row type="flex" class="justify-content-center">
            
            <el-col :span="24" :md="19" :lg="20" type="flex" class="special-box justify-content-center wow fadeInUp" data-wow-delay="0.2s">
              <el-card class="special-pointers">
                <el-row :gutter="20" type="flex" class="justify-content-center">
                  <el-col :xs="24" :sm="5" :md="6" :lg="6" class="hidden-xs">
                    <img src="/images/pointers.png" class="w-100 d-block mx-auto" alt="">
                  </el-col>
                  <el-col :xs="24" :sm="18" :md="16" :lg="17">
                    <ul>
                      <li>No commissions, ever</li>
                      <li>You pay a monthly fee, you can pause at any time</li>
                      <li>Contact the freelancers outside the platform as you wish</li>
                      <li>No bidding, you just put up your job and set your desired rate</li>
                      <li>You have access to all our freelancers</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </div> -->
        <el-row class="mt-5">
          <center>
            <h5 class="wow fadeInUp" data-wow-delay="0.4s">If you have any questions, feel free to email</h5>
            <span class="h5 wow fadeInUp" id="obfuscated" data-wow-delay="0.6s"><canvas style="width: 155px !important; height: 24px !important; cursor: pointer;"></canvas></span> 
            <!-- <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h5>   -->
          </center>
        </el-row>
      </div>
    </div>
    <div class="testimonials">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <center>
              <h5 class="wow fadeInUp" data-wow-delay="0.4s">What our clients say</h5>
              <h2 class="mb-5 wow fadeInUp" data-wow-delay="0.6s">Testimonials</h2>    
            </center>  
          </div>
          <div class="col-md-7 wow fadeInUp" data-wow-delay="0.6s">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/449685620?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
          </div>
          <!-- <div class="col-md-6">
            <div class="row quotation">
              <div class="col-sm-1">
                <img src="images/quotation.png" width="30" height="auto" class="p-0">        
              </div>
              <div class="col-sm-11">
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                </h4>  
                <div class="text-right client mt-4">
                  <h5 class="bold">Client's name here</h5>
                  <h5 class=""><i>Director, Company Name</i></h5>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="faq">
      <div class="container card">
        <div class="row">
          <div class="col-md-4">
            <h5>HAVE QUESTIONS?</h5>
            <h2 class="orange-text">FAQ</h2>
            <p class="pr-5">We are here to help you 7 days a week and respond within 24 hours. Plus, you can find most answers to your questions right on this page.</p>
          </div>
          <div class="col-md-8">
            <el-collapse v-model="activeNames" accordion>
              <el-collapse-item title="Aliquam tincidunt mauris eu risus." name="1">
                <p>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</p>
                <p>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</p>
              </el-collapse-item>
              <el-collapse-item title="Nunc dignissim risus id metus." name="2">
                <p>Operation feedback: enable the users to clearly perceive their operations by style updates and interactive effects;</p>
                <p>Visual feedback: reflect current state by updating or rearranging elements of the page.</p>
              </el-collapse-item>
              <el-collapse-item title="Cras ornare tristique elit." name="3">
                <p>Simplify the process: keep operating process simple and intuitive;</p>
                <p>Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make decisions;</p>
                <p>Easy to identify: the interface should be straightforward, which helps the users to identify and frees them from memorizing and recalling.</p>
              </el-collapse-item>
              <el-collapse-item title="Praesent placerat risus quis eros." name="4">
                <p>Decision making: giving advices about operations is acceptable, but do not make decisions for the users;</p>
                <p>Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or terminating current operation.</p>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import EmailObfuscate from 'email-obfuscate';
//import ContactForm from '~/components/ContactForm'

export default {
  data() {
    return {
      activeName: '1'
    };
  },
  mounted () {
    new WOW().init()
    const el = document.getElementById('obfuscated')
    EmailObfuscate(el, {
      // Email construct: name@domain.tld
      name: 'team',
      domain: 'citronworks',
      tld: 'com',
      // Alternate Text
      altText: 'Email Address'
    });
  },
}
</script>

<style lang="scss" scoped>
.blue-text {
  color: #223d5a;
}
.gray-bg {
  background-color: #f9f9f7;
}
.hero {
  height: 580px;
  padding: 70px 0; 
  background-image: url('/images/employer-hero.png'); 
  background-size: cover;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  color: white;

  h1 {
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }

  h5 {
    font-weight: 400;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }
  button {
    // text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .4px;
    border-radius: 30px;
    padding: 15px 23px;
    width: fit-content;
    font-size: 1.05rem;
    margin-top: 15px;
  }
}
h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

h5 {
  font-size: 1.1rem !important;
  line-height: 1.6;
  letter-spacing: .2px;
  font-weight: 300;
}
.about {
  padding: 70px 0;
}
.how-it-works {
  background-image: url('/images/gray-bg.png');
  background-repeat: no-repeat; 
  background-size: contain;
  padding: 70px 0; 

  .card {
    background-image: url('/images/card-bg.png');
    background-repeat: no-repeat; 
    background-size: contain;
    border-radius: 5px;
    border: 1px solid #f9f9f7;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 50px 30px 90px 30px;
    height: 100%;

    button {
      border-radius: 30px;
      padding: 15px 23px;
      width: fit-content;
      font-size: 1.05rem;
      margin-top: 15px;
    } 
    h4 {
      font-weight: 700;
    }
    .text-right {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }
  }
}
.testimonials {
  padding: 70px 0; 
  background-image: url('/images/testimonials-bg.png'); 
  background-size: cover;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);

  h5 {
    text-transform: uppercase;
    color: white;
  }
  .client h5 {
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .client h5.bold {
    font-weight: 600;
    text-transform: uppercase;
  }

  h2 {
   color: white; 
  }

  h4 {
    line-height: 1.5;
    font-weight: 400;
    color: white;
    font-size: 1.3rem;
  }
}
.faq {
  padding: 70px 0; 
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);

  .card {
    padding: 30px;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  }
  .el-collapse {
    border: transparent;
  }
  .el-collapse-item:first-child {
    .el-collapse-item__wrap {
      border-top: none;
    }
  }

  h5 {
    text-transform: uppercase;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .hero {
    background-position: center;
  }
}
@media (min-width: 993px) {
  .hero {
    background-position: center;
  }
}
@media (min-width: 1200px) {
  .hero {
    background-position: right;
  }
}

@media (max-width: 1199px) {

}
@media (max-width: 992px) {
  
}
@media (max-width: 767px) {
  .how-it-works .card {
    margin-bottom: 20px;
  }
  .how-it-works .card.last {
    margin-bottom: 0px;
  }
  .hero {
    height: auto;
  }
  .hero h5, .hero h1 {
    max-width: 450px;
  }
  .quotation {
    margin-top: 30px;
  }
  .faq {
    padding: 0;
    box-shadow: none;
  }
  .faq .card {
    padding: 70px 15px;
    border-radius: 0;
  }
}
@media (max-width: 500px) { 
  .hero {
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
</style>

<style>
  .why-cw {
    padding-top: 70px;
  }
  .why-cw .divider {
    margin-bottom: 70px;
  }
  .why-cw h2 {
    line-height: 1.4;
  }
  .special-box {
      padding: 15px;
      background: rgba(253, 203, 25, 0.1);
      border: 1px solid #fdcb19;
      border-radius: 5px;
  }
  .special-pointers {
    /*border-color: #ec8435;
    background: #ec8435;*/
    border-radius: 5px !important;
  }
  .special-pointers ul li{
    padding: 5px 0;
    list-style: none;
    
  }
  .special-pointers li {
    margin-left: 1.3em;
    font-size: 1.1rem !important;
    /*color: #E87722;*/
    font-weight: 300;
  }
  .special-pointers li:before {
    content: "\e79c";
    font-family: element-icons!important;
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
    width: 1.5em; /* same as padding-left set on li */
    color: #E87722;;
  }
  
  .special-pointers ul {
    padding: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0;
  }
  .special-pointers ul li {
    letter-spacing: .8px;
    margin-left: 2em;
  }
</style>
